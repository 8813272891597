a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
}

.nav-link {
	text-align: right;
	font-size:1.2em;
}

html {
	font-size: 14px;
}

@media (min-width: 768px) {
	html {
		font-size: 16px;
	}
}

.box-shadow {
	box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.active {
	color: #f03fa8 !important;
}

.social-media-container {
	width: 100%;
	text-align: center;
}

.social-media {
	max-width: 56px;
	width: 1em;
}

.social-media:hover {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.logo-container {
	width: 100%;
}

.social-icon img {
	min-width: 32px;
	width: 1em;
	margin: 1em;
}
