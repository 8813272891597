.thumbnail a:hover {
	text-decoration: none;
}

.miniature-container {
	width: 100%;
	padding-top: 100%;
	position: relative;
	overflow: hidden;
}

.miniature-container:hover .miniature-overlay {
	opacity: 0.7;
}

.miniature-container:hover img {
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-o-transform: scale(1.3);
	transform: scale(1.3);
	transition: .5s ease;
}

.miniature {
	width: 100%;
}

.miniature-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: .5s ease;
	background-color: black;
}

.miniature-caption {
	color: white;
	background-color: black;
	position: absolute;
	bottom: 40%;
	left: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
}
