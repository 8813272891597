/* clearfix */
.grid:after {
	content: '';
	display: block;
	clear: both;
}

.hidden {
	display: none;
}

.mosaic-row {
	text-align: center;
}

.grid-sizer, .grid-item {
	overflow: hidden;
	margin: 0.2vh;
	padding: 0;
	width: auto;
}

.grid-item img{
	/*height: 8em;*/
	height: 15vh;
	max-height: 240px;
}

.grid-item:hover img {
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-o-transform: scale(1.3);
	transform: scale(1.3);
	transition: .5s ease;
}
